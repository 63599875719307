/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
import React, { useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import Electron from './Electron'
import Nucleus from './Nucleus'

export default function Atom ({ position, key }) {
  const ref = useRef()
  useFrame((_, delta) => {
    ref.current.position.x -= 2 * delta * (1 + ((Math.abs(position[0]) + Math.abs(position[1]) + Math.abs(position[2])) / 50))
    if (ref.current.position.x < -0.05 * window.innerWidth) {
      ref.current.position.x = 0.05 * window.innerWidth
    }
  })
  return (
      <group ref={ref} position={position}>
        <Electron xoffset={-1} yoffset={0.25} zoffset={-1} color={'lightgreen'} speed={9} />
        <Electron xoffset={2} yoffset={-2} zoffset={2} color={'lightgreen'} speed={9} />
        <Electron xoffset={3} yoffset={1} zoffset={2} color={'lightgreen'} speed={9} />
        <Nucleus />
      </group>
  )
}
