/* eslint-disable react/no-unknown-property */
import React from 'react'
import Die from './Die'

export default function DieScene () {
  return (
      <>
        <ambientLight />
        <pointLight intensity={0.45} position={[-1, 15, 0]} />
        <Die />
      </>
  )
}
