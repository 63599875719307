/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
import React from 'react'
import BrokenElectron from './BrokenElectron'
import Electron from './Electron'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import Proton from './Proton'

export default function BrokenAtomScene ({ onOff }) {
  return (
      <>
        <ambientLight />
        <pointLight intensity={0.45} position={[-1, 15, 0]} />
        <BrokenElectron position={[-0.5, -1.6, 0]} />
        {onOff ? <Electron xoffset={2} yoffset={-1} zoffset={2} speed={2} color={'lightgreen'} /> : <Electron xoffset={2} yoffset={-1} zoffset={2} speed={2} color={'gray'} />}
        <BrokenElectron position={[0.8, -1.6, 0]} />
        <Proton position={[-1, -1.5, 0]} color={'yellow'} />
        <Proton position={[0.5, -1.5, 0]} color={'yellow'} />
        <Proton position={[2.2, -1.5, 0]} color={'yellow'} />
        <Proton position={[1.75, -1.5, 0]} color={'gray'} />
        <Proton position={[-1.5, -1.5, 0]} color={'gray'} />
        <Proton position={[-1.75, -1.5, 0]} color={'gray'} />
        <Proton position={[0, -1.5, 0]} color={'gray'} />
        <EffectComposer>
          <Bloom luminanceThreshold={0.5} luminanceSmoothing={0.9} height={300} intensity={5} />
        </EffectComposer>
      </>
  )
}
