/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
import React from 'react'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import Atom from './Atom'

export default function AtomScene () {
  const data = [
    [1, [0 * window.innerWidth * 0.0025, 0, 0]],
    [2, [5 * window.innerWidth * 0.0025, 2, 2]],
    [3, [4 * window.innerWidth * 0.0025, -2, 2]],
    [4, [-5 * window.innerWidth * 0.0025, 3, 7]],
    [5, [-33 * window.innerWidth * 0.0025, 2, 0]],
    [6, [-12 * window.innerWidth * 0.0025, 9, 12]],
    [7, [-15 * window.innerWidth * 0.0025, 2, 2]],
    [8, [-11 * window.innerWidth * 0.0025, 7, 7]],
    [9, [-25 * window.innerWidth * 0.0025, -5, 5]],
    [10, [-3 * window.innerWidth * 0.0025, -3, 4]],
    [11, [-8 * window.innerWidth * 0.0025, -5, 5]],
    [12, [-12 * window.innerWidth * 0.0025, -7, 0]],
    [13, [-1 * window.innerWidth * 0.0025, 8, 11]],
    [14, [-15 * window.innerWidth * 0.0025, -9, 5]],
    [15, [-12 * window.innerWidth * 0.0025, 9, 9]],
    [16, [5 * window.innerWidth * 0.0025, -3, 7]],
    [17, [33 * window.innerWidth * 0.0025, -2, 4]],
    [18, [12 * window.innerWidth * 0.0025, -9, 8]],
    [19, [15 * window.innerWidth * 0.0025, -2, 4]],
    [20, [11 * window.innerWidth * 0.0025, -7, 7]],
    [21, [25 * window.innerWidth * 0.0025, 5, 5]],
    [22, [3 * window.innerWidth * 0.0025, 3, 4]],
    [23, [8 * window.innerWidth * 0.0025, 5, 5]],
    [24, [12 * window.innerWidth * 0.0025, 7, 0]],
    [25, [1 * window.innerWidth * 0.0025, -8, 8]],
    [26, [15 * window.innerWidth * 0.0025, 9, 5]],
    [27, [21 * window.innerWidth * 0.0025, -7, 7]],
    [28, [30 * window.innerWidth * 0.0025, -1, 4]],
    [29, [28 * window.innerWidth * 0.0025, -5, 0]],
    [30, [22 * window.innerWidth * 0.0025, 2, 3]],
    [31, [-20 * window.innerWidth * 0.0025, -2, 3]]
  ]

  return (
      <>
        <ambientLight />
        <pointLight intensity={0.45} position={[-1, 15, 0]} />
        <group position={[0, 0, -20]}>
          {data.map((i) => (
            <Atom key={i[0]} position={i[1]} rotation={0} />
          ))}
        </group>
        <EffectComposer>
          <Bloom luminanceThreshold={0.5} luminanceSmoothing={0.9} height={300} intensity={1} />
        </EffectComposer>
      </>
  )
}
