
/* eslint-disable react/no-unknown-property */
import React, { useRef } from 'react'

// eslint-disable-next-line react/prop-types
export default function BrokenElectron ({ position, onOff }) {
  const ref = useRef()
  const radius = 0.1

  return (
      <mesh position={position} ref={ref}>
        <sphereBufferGeometry args={[radius]} />
        <meshStandardMaterial attach="material" color='lightgreen' toneMapped={false} />
      </mesh>
  )
}
