
/* eslint-disable react/no-unknown-property */
import React, { useRef } from 'react'
import { useFrame } from '@react-three/fiber'

// eslint-disable-next-line react/prop-types
export default function Electron ({ xoffset, yoffset, zoffset, speed, color }) {
  const ref = useRef()
  const radius = 0.1
  const electronoffset = Math.random()

  useFrame(({ state, delta, clock }) => {
    ref.current.position.x = Math.sin(speed * (clock.getElapsedTime() + electronoffset) + xoffset)
    ref.current.position.z = Math.sin(speed * (clock.getElapsedTime() + electronoffset) - 1.55 + zoffset)
    ref.current.position.y = Math.sin(speed * (clock.getElapsedTime() + electronoffset) - 1.55 + yoffset)
  })

  return (
      <mesh ref={ref}>
        <sphereBufferGeometry args={[radius]} />
        <meshStandardMaterial attach="material" color={color} toneMapped={false} />
      </mesh>
  )
}
