/* eslint-disable react/no-unknown-property */
import './Home.css'
import React from 'react'
import { Canvas } from '@react-three/fiber'
import AtomScene from '../components/AtomsScene'
import DieScene from '../components/DieScene.js'
// import { Swiper, SwiperSlide } from 'swiper/react'
// import { Navigation, EffectFlip } from 'swiper'
// import 'swiper/css'
// import 'swiper/css/effect-flip'
// import 'swiper/css/navigation'

export default function Home () {
  return (
    <div className="Home">
      <div className='title'>
        <div>Harold Tourjee, III</div>
        <div className='subtitle'>React Developer</div>
      </div>
      <div className='canvascontainer'>
        <Canvas>
          <AtomScene />
        </Canvas>
      </div>
      <div className='homecontent'>
        <p>Husband | Father | Programmer</p>
        <p>I have always been one with computers and the way they speak. I am driven to solve problems and patient enough to give consistent results.</p>
      </div>
      <div className="portfolio" id='portfolio'>
        <div className='subtitle'>Projects</div>
        <div className='horizontalbar'></div>
        <div className='subtext'>Fully functional programs and websites I&apos;ve completed</div>
        <div className='projects'>
          {/* <div className='carouselcontainer'>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              speed={800}
              loop={true}
              navigation={true}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
              modules={[Navigation, EffectFlip]}
              effect='flip'
            >
              <SwiperSlide>
                <div className='projectcontainercontainercontainer'>
                  <div className='projectcontainercontainer'>
                    <div className="statGenerator projectcontainer">
                      <a className='projectcontaineroverlay' href="https://estatgenerator.web.app/" target="_blank" rel="noreferrer">
                        <div className="project">D&D Stat Generator</div>
                      </a>
                    </div>
                    <a className="sourcecode" href="https://github.com/Kodeden/HaroldD-DCharacterBuilder" target="_blank" rel="noreferrer">
                      <div>Source Code</div>
                    </a>
                  </div>
                  <div className='projectdescription'>A React Web-app I created which is utilized by my local D&D community to streamline character creation. It contains Formik input handling, Yup and Formik data validation, and automated testing of components with React-Jest.</div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='projectcontainercontainercontainer'>
                  <div className='projectcontainercontainer'>
                    <div className="KIT projectcontainer">
                      <a className='projectcontaineroverlay' href="https://youtube.com/embed/ABYPxutCmL0?autoplay=1" target="_blank" rel="noreferrer">
                        <div className="project">Keep In Touch App</div>
                      </a>
                    </div>
                    <a className="sourcecode" href="https://github.com/Kodeden/KIT" target="_blank" rel="noreferrer">
                      <div>Source Code</div>
                    </a>
                  </div>
                  <div className='projectdescription'>A project to help you keep track of when you last had contact with your friends and family. Built in React Native via Expo, it utilizes a Redux backend alongside Async-Storage, and Stack navigation. It includes automated testing of components with Jest.</div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='projectcontainercontainercontainer'>
                  <div className='projectcontainercontainer'>
                    <div className="CC projectcontainer">
                      <a className='projectcontaineroverlay' href="https://youtube.com/embed/P_3WLe2AklA?autoplay=1" target="_blank" rel="noreferrer">
                        <div className="project">Card Collector App</div>
                      </a>
                    </div>
                    <a className="sourcecode" href="https://github.com/Kodeden/Harold-CardCollectionManager" target="_blank" rel="noreferrer">
                      <div>Source Code</div>
                    </a>
                  </div>
                  <div className='projectdescription'>A project to help you keep track of a baseball card collection. Built in React, it utilizes a mixed Apollo server and Express server backend, utilizing Sequelize, Graphql, and SQLite. I packaged it with Nexe, and it includes automated testing of components with React-Jest.</div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='projectcontainercontainercontainer'>
                  <div className='projectcontainercontainer'>
                    <div className="oldportfolio projectcontainer">
                      <a className='projectcontaineroverlay' href="https://youtube.com/embed/P_3WLe2AklA?autoplay=1" target="_blank" rel="noreferrer">
                        <div className="project">My Old Portfolio Site</div>
                      </a>
                    </div>
                    <a className="sourcecode" href="https://github.com/Kodeden/HaroldOldPortfolioSite" target="_blank" rel="noreferrer">
                      <div>Source Code</div>
                    </a>
                  </div>
                  <div className='projectdescription'>A link to my previous portfolio website, an entrance to nostalgia. It is built with minimal JavaScript and no image files. Almost all effects and graphics are created with CSS and HTML. I&apos;ve learned a lot since I made it, but I&apos;m proud of where I started.</div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div> */}
          <div className='projectcontainercontainercontainer'>
            <div className='projectcontainercontainer'>
              <div className="SSBSite projectcontainer">
                <a className='projectcontaineroverlay' href="https://ssbsite-c738a.web.app/" target="_blank" rel="noreferrer">
                  <div className="project">Southside Baptist Brandon Site</div>
                </a>
              </div>
              <a className="sourcecode" href="https://github.com/Kodeden/SSBSite" target="_blank" rel="noreferrer">
                <div>Source Code</div>
              </a>
            </div>
            <div className='projectdescription'>The official site for my local church. I created it in React as a donation and will manage it after it goes live when their current subscription ends. It also contains Formik input handling, Yup and Formik data validation, and automated testing of components with React-Jest.</div>
          </div>
          <div className='projectcontainercontainercontainer'>
            <div className='projectcontainercontainer'>
              <div className="statGenerator projectcontainer">
                <a className='projectcontaineroverlay' href="https://estatgenerator.web.app/" target="_blank" rel="noreferrer">
                  <div className="project">D&D Stat Generator</div>
                </a>
              </div>
              <a className="sourcecode" href="https://github.com/Kodeden/HaroldD-DCharacterBuilder" target="_blank" rel="noreferrer">
                <div>Source Code</div>
              </a>
            </div>
            <div className='projectdescription'>A React Web-app I created which is utilized by my local D&D community to streamline character creation. It contains Formik input handling, Yup and Formik data validation, and automated testing of components with React-Jest.</div>
          </div>
          <div className='projectcontainercontainercontainer'>
            <div className='projectcontainercontainer'>
              <div className="KIT projectcontainer">
                <a className='projectcontaineroverlay' href="https://youtube.com/embed/ABYPxutCmL0?autoplay=1" target="_blank" rel="noreferrer">
                  <div className="project">Keep In Touch App</div>
                </a>
              </div>
              <a className="sourcecode" href="https://github.com/Kodeden/KIT" target="_blank" rel="noreferrer">
                <div>Source Code</div>
              </a>
            </div>
            <div className='projectdescription'>A project to help you keep track of when you last had contact with your friends and family. Built in React Native via Expo, it utilizes a Redux backend alongside Async-Storage, and Stack navigation. It includes automated testing of components with Jest.</div>
          </div>
          <div className='projectcontainercontainercontainer'>
            <div className='projectcontainercontainer'>
              <div className="CC projectcontainer">
                <a className='projectcontaineroverlay' href="https://youtube.com/embed/P_3WLe2AklA?autoplay=1" target="_blank" rel="noreferrer">
                  <div className="project">Card Collector App</div>
                </a>
              </div>
              <a className="sourcecode" href="https://github.com/Kodeden/Harold-CardCollectionManager" target="_blank" rel="noreferrer">
                <div>Source Code</div>
              </a>
            </div>
            <div className='projectdescription'>A project to help you keep track of a baseball card collection. Built in React, it utilizes a mixed Apollo server and Express server backend, utilizing Sequelize, Graphql, and SQLite. I packaged it with Nexe, and it includes automated testing of components with React-Jest.</div>
          </div>
          <div className='projectcontainercontainercontainer'>
            <div className='projectcontainercontainer'>
              <div className="oldportfolio projectcontainer">
                <a className='projectcontaineroverlay' href="https://harold-old-porfolio.web.app/" target="_blank" rel="noreferrer">
                  <div className="project">My Old Portfolio Site</div>
                </a>
              </div>
              <a className="sourcecode" href="https://github.com/Kodeden/HaroldOldPortfolioSite" target="_blank" rel="noreferrer">
                <div>Source Code</div>
              </a>
            </div>
            <div className='projectdescription'>A link to my previous portfolio website, an entrance to nostalgia. It is built with minimal JavaScript and no image files. Almost all effects and graphics are created with CSS and HTML. I&apos;ve learned a lot since I made it, but I&apos;m proud of where I started.</div>
          </div>
        </div>
      </div>
      <div className='canvascontainer'>
        <Canvas orthographic camera={{ zoom: 50, position: [0, 0, 100] }}>
          <DieScene />
        </Canvas>
      </div>
      <div className='about' id='about'>
        <div className='subtitle'>About</div>
        <div className='horizontalbar'></div>
        <p>My name is Harold Tourjee, III.
          I manipulate code into both form and function,
          creating websites and applications from HTML, CSS, and JavaScript.
          I am persistent and creative, so no problem is insurmountable,
          and there is nothing I like better than solving a challenge.
        </p>
        <p>
          As well as creating user-facing sites and applications,
          I dabble in some database manipulation. I know enough to get things done,
          and I am a fast learner and researcher so whatever I cannot do, I do anyway.
        </p>
        <p>When I&apos;m not creating online, I am creating memories with my wife, son, and daughter.
          We enjoy visiting the zoo, reading, and playing games.
        </p>
      </div>
      <div className='Contact' id='contactinfo'>
      <div className='subtitle'>Contact Me</div>
      <div className='contactinfo'>
        <div>Phone: (813) 957-0246</div>
        <div>Email: <a href="mailto:htourjee@gmail.com">htourjee@gmail.com</a></div>
      </div>
    </div>
    </div>
  )
}
