/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import './App.css'
import React, { useState, useEffect } from 'react'
import { Routes, Route, useNavigate, useParams, useLocation } from 'react-router-dom'
import { animateScroll as scroll, scroller } from 'react-scroll'
import Home from './screens/Home'
import Resume from './screens/Resume'
import ErrorPage from './screens/404'

function App () {
  const navigate = useNavigate()
  const [element, setElement] = useState('top')
  const location = useLocation()

  React.useEffect(() => {
    scroller.scrollTo(element, { duration: 800, smooth: 'easeInOutQuart', offset: -30 })
  }, [location])

  return (
    <div className="App">
        <div className="navcontainer">
          <div className='navmenu'>
            <div
              className='menuitem'
              onClick={() => {
                setElement('top')
                navigate('/home')
                scroller.scrollTo('top', { duration: 800, smooth: 'easeInOutQuart' })
              }}>Home</div>
            <div
              className='menuitem'
              onClick={() => {
                setElement('portfolio')
                navigate('/home')
                scroller.scrollTo('portfolio', { duration: 800, smooth: 'easeInOutQuart', offset: -30 })
              }}>Portfolio</div>
            <div
              className='menuitem'
              onClick={() => {
                setElement('about')
                navigate('/home')
                scroller.scrollTo('about', { duration: 800, smooth: 'easeInOutQuart', offset: -30 })
              }}>About</div>
            <div
              className='menuitem'
              onClick={() => {
                setElement('contactinfo')
                navigate('/home')
                scroller.scrollTo('contactinfo', { duration: 800, smooth: 'easeInOutQuart', offset: -30 })
              }}>Contact</div>
            <div
              className='menuitem'
              onClick={() => {
                setElement('Resume')
                navigate('/resume')
              }}>Resum&#233;</div>
          </div>
        </div>
        <div className='routecontainer' id='top'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
    </div>
  )
}
export default App
