import './404.css'
import React, { useEffect, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import BrokenAtomScene from '../components/BrokenAtomScene'

export default function ErrorPage () {
  const [onOff, setOnOff] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setOnOff(Math.floor(Math.random() * 2) - 1)
    }, (Math.random() * 2000))

    return () => clearInterval(interval)
  }, [])
  return (
    <div className="fourohfour">
      <div className='canvascontainer'>
        <Canvas>
          <BrokenAtomScene onOff={onOff} />
        </Canvas>
        <div className='fourohfour'>
          <div className='title'>Oops</div>
          <div className='errortext'>Harold hasn&apos;t coded this page yet. Try clicking another link from the navigation menu.</div>
        </div>
      </div>
    </div>
  )
}
