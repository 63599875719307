/* eslint-disable react/no-unknown-property */
import React, { useRef, useLayoutEffect } from 'react'
import * as THREE from 'three'
import { useFrame } from '@react-three/fiber'

export default function Die () {
  const ref = useRef()

  const acceleration = 9.8
  const bounceDistance = 2
  const bottomPositionY = 0
  const timeStep = 0.02
  let timeCounter = Math.sqrt(bounceDistance * 2 / acceleration)
  const initialSpeed = acceleration * timeCounter

  const textColor = 'white'
  const dieColor = 'black'
  const sides = 20
  const verticesPerFace = 3
  const radius = 0.8

  const calculateTextureSize = (approx) => {
    return Math.max(128, Math.pow(2, Math.floor(Math.log(approx) / Math.log(2))))
  }

  const createUvs = (sides, fl, tab, af) => {
    // TODO Store tab and af as variables for each shape

    const uvs = []
    const aa = (Math.PI * 2) / fl

    for (let i = 0; i < sides; ++i) {
      for (let j = 0; j < fl - 2; ++j) {
        for (let k = 0; k < 3; ++k) {
          const theta = aa * (j + k)

          uvs.push(
            // u
            (Math.cos(theta + af) + 1 + tab) / 2 / (1 + tab),
            // v
            (Math.sin(theta + af) + 1 + tab) / 2 / (1 + tab)
          )
        }
      }
    }

    return new Float32Array(uvs)
  }
  useLayoutEffect(() => {
    if (ref.current) {
      for (let i = 0; i < sides; i++) {
        ref.current.geometry.addGroup(i * verticesPerFace, verticesPerFace, i)
      }
      ref.current.geometry.setAttribute('uv', new THREE.Float32BufferAttribute(createUvs(sides, verticesPerFace, 0, -Math.PI / 8), 2))
    }
  }, [])

  useFrame((_, delta) => {
    let xspin = 1
    let yspin = 1
    let zspin = 1
    ref.current.rotation.x += xspin * delta
    ref.current.rotation.y += yspin * delta
    ref.current.rotation.z += zspin * delta
    if (ref.current.position.y < bottomPositionY) {
      timeCounter = 0
      xspin = Math.random() * 100
      zspin = Math.random() * 100
      yspin = Math.random() * 100
    }
    ref.current.position.y = bottomPositionY + initialSpeed * timeCounter - 0.5 * acceleration * timeCounter * timeCounter
    timeCounter += timeStep
    ref.current.position.x += Math.min((0.0075 * window.innerWidth * delta), (4 * delta))
    if (ref.current.position.x > 0.012 * window.innerWidth) {
      ref.current.position.x = -0.012 * window.innerWidth
    }
  })
  function createTextTexture (text, color, backColor, size = radius, textMargin = 1.5) {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const ts = calculateTextureSize(size / 2 + size * textMargin) * 2
    canvas.width = canvas.height = ts
    context.font = ts / (1 + 2 * textMargin) + 'pt Arial'
    context.fillStyle = backColor
    context.fillRect(0, 0, canvas.width, canvas.height)
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    context.fillStyle = color
    context.fillText(text, canvas.width / 2, canvas.height / 2)
    if (text === 6 || text === 9) {
      context.fillText('  .', canvas.width / 2, canvas.height / 2)
    }
    const texture = new THREE.Texture(canvas)
    texture.needsUpdate = true
    return texture
  }

  return (
      <mesh position={[-0.0075 * window.innerWidth, 0, 0]} ref={ref}>
        <icosahedronBufferGeometry args={[radius]} />
        <meshStandardMaterial attach="material-0" map={createTextTexture(1, textColor, dieColor)} />
        <meshStandardMaterial attach="material-1" map={createTextTexture(19, textColor, dieColor)} />
        <meshStandardMaterial attach="material-2" map={createTextTexture(3, textColor, dieColor)} />
        <meshStandardMaterial attach="material-3" map={createTextTexture(17, textColor, dieColor)} />
        <meshStandardMaterial attach="material-4" map={createTextTexture(7, textColor, dieColor)} />
        <meshStandardMaterial attach="material-5" map={createTextTexture(9, textColor, dieColor)} />
        <meshStandardMaterial attach="material-6" map={createTextTexture(13, textColor, dieColor)} />
        <meshStandardMaterial attach="material-7" map={createTextTexture(15, textColor, dieColor)} />
        <meshStandardMaterial attach="material-8" map={createTextTexture(10, textColor, dieColor)} />
        <meshStandardMaterial attach="material-9" map={createTextTexture(16, textColor, dieColor)} />
        <meshStandardMaterial attach="material-10" map={createTextTexture(4, textColor, dieColor)} />
        <meshStandardMaterial attach="material-11" map={createTextTexture(18, textColor, dieColor)} />
        <meshStandardMaterial attach="material-12" map={createTextTexture(2, textColor, dieColor)} />
        <meshStandardMaterial attach="material-13" map={createTextTexture(20, textColor, dieColor)} />
        <meshStandardMaterial attach="material-14" map={createTextTexture(14, textColor, dieColor)} />
        <meshStandardMaterial attach="material-15" map={createTextTexture(6, textColor, dieColor)} />
        <meshStandardMaterial attach="material-16" map={createTextTexture(11, textColor, dieColor)} />
        <meshStandardMaterial attach="material-17" map={createTextTexture(5, textColor, dieColor)} />
        <meshStandardMaterial attach="material-18" map={createTextTexture(12, textColor, dieColor)} />
        <meshStandardMaterial attach="material-19" map={createTextTexture(8, textColor, dieColor)} />
      </mesh>
  )
}
