/* eslint-disable react/no-unknown-property */
import React, { useRef } from 'react'

// eslint-disable-next-line react/prop-types
export default function Proton ({ position, color }) {
  const ref = useRef()
  const radius = 0.2

  return (
      <mesh position={position} ref={ref} uuid={1}>
        <sphereBufferGeometry args={[radius]} />
        <meshStandardMaterial attach="material" color={color} />
      </mesh>
  )
}
