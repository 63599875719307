/* eslint-disable react/no-unknown-property */
import React, { useRef } from 'react'
import Proton from './Proton'
import { useFrame } from '@react-three/fiber'

// eslint-disable-next-line react/prop-types
export default function Nucleus ({ position, color }) {
  const ref = useRef()

  useFrame((_, delta) => {
    ref.current.rotation.y += 0.03
    ref.current.rotation.x += 0.01
  })

  return (
    <group ref={ref}>
      <Proton position={[0, 0.15, 0]} color={'yellow'} />
      <Proton position={[-0.15, -0.1, 0]} color={'yellow'} />
      <Proton position={[0.15, -0.1, 0]} color={'yellow'} />
      <Proton position={[0, -0.15, 0.1]} color={'gray'} />
      <Proton position={[0, -0.15, -0.1]} color={'gray'} />
      <Proton position={[-0.15, 0.10, -0.05]} color={'gray'} />
      <Proton position={[0.15, 0.10, 0.05]} color={'gray'} />
    </group>
  )
}
