import './Resume.css'
import React from 'react'

export default function Resume () {
  return (
    <div className='Resume'>
      <div className='resumetitle'>My Resum&#233;</div>
      <div className='subtitle'>Education</div>
      <div className='horizontalbar'></div>
      <div className='resumesection'>
        <h2>B.S. Information Technology</h2>
          <ul>
            <li><p>University of South Florida, December 2015</p></li>
            <li><p>Security Specialization</p></li>
          </ul>
        <h2>A.S. Information Technology</h2>
          <ul>
            <li><p>Indian River State College, December 2010</p></li>
            <li><p>Information Technology Support Specialist Certificate</p></li>
          </ul>
        <h2>Other Certifications</h2>
          <ul><li><p>CompTIA A+ Certification</p></li></ul>
        <h2>Kodeden (<a href="https://kodeden.com" target="_blank" rel="noreferrer">kodeden.com</a>)</h2>
          <ul><li><p>Full Stack Software Engineering 6 Month Cohort, October 2022</p></li></ul>
      </div>
      <div className='subtitle'>Projects</div>
      <div className='horizontalbar'></div>
      <div className='resumesection'>
        <h2>Southside Baptist Brandon Website</h2>
          <ul>
            <li><a href="https://ssbsite-c738a.web.app/" target="_blank" rel="noreferrer">https://ssbsite-c738a.web.app/</a></li>
            <li><p>The official site for my local church. I created it in React as a donation and will manage it after it goes live when their current subscription ends. It also contains Formik input handling, Yup and Formik data validation, and automated testing of components with React-Jest.</p></li>
          </ul>
        <h2>Card Collection App</h2>
          <ul>
            <li><a href="https://github.com/Kodeden/Harold-CardCollectionManager" target="_blank" rel="noreferrer">https://github.com/Kodeden/Harold-CardCollectionManager</a></li>
            <li><p>A project to help you keep track of a baseball card collection. Built in React, it utilizes a mixed Apollo server and Express server backend, utilizing Sequelize, Graphql, and SQLite. I packaged it with Nexe, and it includes automated testing of components with React-Jest.</p></li>
          </ul>
        <h2>Keep in Touch App</h2>
          <ul>
            <li><a href="https://github.com/Kodeden/KIT" target="_blank" rel="noreferrer">https://github.com/Kodeden/KIT</a></li>
            <li><p>A project to help you keep track of when you last had contact with your friends and family. Built in React Native via Expo, it utilizes a Redux backend alongside Async-Storage, and Stack navigation. It includes automated testing of components with Jest.</p></li>
          </ul>
        <h2>D&D Stat Generator</h2>
          <ul>
            <li><a href="https://github.com/Kodeden/HaroldD-DCharacterBuilder" target="_blank" rel="noreferrer">https://github.com/Kodeden/HaroldD-DCharacterBuilder</a></li>
            <li><p>A React Web-app I created which is utilized by my local D&D community to streamline character creation. It contains Formik input handling, Yup and Formik data validation, and automated testing of components with React-Jest.</p></li>
          </ul>
      </div>
      <div className='subtitle'>Experience</div>
      <div className='horizontalbar'></div>
      <div className='resumesection'>
        <h2>Stay-At-Home-Father</h2>
        <ul>
          <li><p>May 2018 – Current</p></li>
          <li><p>Starting in May 2018, I transitioned into staying home to take care of my two children so my amazing wife could continue her career as a Nurse. However, I have decided it is time to get back into the workforce. In my time as a Dad I have honed skills in budgeting finances, scheduling time appropriately, balancing priorities, and of course patience.</p></li>
        </ul>
        <h2>KodeDen Student</h2>
        <ul>
          <li><p>May 2022 – Current</p></li>
          <li><p>I create a number of projects to gain hands-on experience with a variety of programming frameworks and skills designed to equip me to be a Full Stack Software Engineer.</p></li>
        </ul>
        <h2>Website Manager</h2>
        <ul>
          <li><p>June 2022 – Current</p></li>
          <li><p>Southside Baptist Church Brandon, FL</p></li>
          <li><p>I perform content and design updates to the church website, as requested by the pastors and other church representatives.</p></li>
        </ul>
        <h2>Service Desk Specialist</h2>
        <ul>
          <li><p>May 2016 – May 2018</p></li>
          <li><p>Zymphony Technology Solutions Tampa, FL</p></li>
          <li><p>In a Help Desk environment I answered telephones and chat sessions, and traveled for onsite visits. My basic job duties were to troubleshoot and resolve computer and network issues, both hardware and software, documenting all steps as I went. I took it upon myself to use Powershell scripting to automate a number of tasks that I noticed were having to be done repeatedly to save the company time and money.</p></li>
        </ul>
        <h2>IT Student Intern</h2>
        <ul>
          <li><p>August 2015 – November 2015</p></li>
          <li><p>Sarasota County Government</p></li>
          <li><p>As part of my degree prerequisites, I shadowed and assisted a wide range of individuals supporting the IT infrastructure of Sarasota County Government officials and employees.</p></li>
        </ul>
        <h2>Computer Support Professional</h2>
        <ul>
          <li><p>May 2011 – August 2015</p></li>
          <li><p>Convergys - Previously Stream Global Services Tampa, FL</p></li>
          <li><p>In a Help Desk environment I answered telephones and chat sessions. I remotely troubleshot and resolved computer hardware and software issues, documenting all steps and cross-selling replacement hardware or warranty upgrades/extensions as appropriate. I assisted others in the same role and was occasionally put into a full time teaching role as needed.</p></li>
        </ul>
        <h2>Lights and Sound Booth Operator</h2>
        <ul>
          <li><p>July 2012 – 2019</p></li>
          <li><p>Southside Baptist Church Brandon, FL</p></li>
          <li><p>In an A/V environment I recorded audio/video media of two church services a week, and ensured sound levels and equalization were optimized during service.</p></li>
        </ul>
      </div>
      <div className='subtitle'>Skills</div>
      <div className='horizontalbar'></div>
      <div className="skills">
        <div>
          <h2>Programming Languages</h2>
            <div>Javascript</div>
            <div>HTML</div>
            <div>CSS</div>
            <div>Java</div>
            <div>C</div>
            <div>C++</div>
        </div>
        <div>
          <h2>Frameworks</h2>
            <div>React</div>
            <div>React Native</div>
            <div>Express.js</div>
            <div>Node.js</div>
            <div>Redux</div>
        </div>
        <div>
          <h2>Automated Testing</h2>
            <div>Jest</div>
            <div>React Testing Library</div>
            <div>Supertest</div>
        </div>
        <div>
          <h2>Database</h2>
            <div>PostGreSQL</div>
            <div>SQLite</div>
        </div>
      </div>
    </div>
  )
}
